@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sacramento';
  src: local('Sacramento'), url(./assets/fonts/Sacramento-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url(./assets/fonts/Fredoka-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Fredoka-Light';
  src: local('Fredoka'), url(./assets/fonts/Fredoka-Light.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

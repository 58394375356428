/*@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Varela+Round&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');*/

:root {
  --breathing: linear-gradient(180deg, #9b59b6, #8d4aa9);
  --breathing-shadow: rgba(144, 88, 141, 0.3);
  --breathing-shadow-btn: rgba(144, 88, 141, 0);
  --logo: linear-gradient(180deg, #9b59b6, #8d4aa9);
  --text-color: #eeeeee;
  --light: #ffa300;
  --dark: #cb8200;
  /*--purple: #580057;*/
  --purple: #5e3b61;
  /*--blue: #002b58;*/
  --blue: #05273f;
  --btn-gradient: linear-gradient(180deg, #9b59b6, #8d4aa9);
  --app-background: linear-gradient(320deg, var(--light), var(--purple) 70% 100%) fixed;
}

* {
  user-select: none;
  --webkit-user-select: none;
}

/*html {*/
/*  scrollbar-gutter: stable;*/
/*}*/

body,
html, .app, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Fredoka", Arial, sans-serif;
  background: var(--app-background);
  /*background: linear-gradient(320deg, var(--blue), var(--purple) 70% 100%) fixed;*/
  color: var(--text-color);
}

.sacramento {
  font-family: "Sacramento", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2.0rem;
  letter-spacing: 0.15rem;
}

.light {
  font-family: "Fredoka-Light", Arial, sans-serif;
}

.background-app {
  background: var(--app-background);
}

.app {
  display: flex;
  justify-content: center;
  margin: env(safe-area-inset-top)
          env(safe-area-inset-right)
          env(safe-area-inset-bottom)
          env(safe-area-inset-left);
}

.page {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 12px;
}

p.secondary, h1.secondary {
  color: rgba(255, 255, 255, 0.6)
}

button.btn {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  width: 250px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
}

button.btn.circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

button.btn.circle.big {
  width: 56px;
  height: 56px;
}

button.btn:disabled {
  color: rgba(255, 255, 255, 0.4);
}

button.primary {
  color: #444;
  background-color: var(--light);
}

button.btn.primary:hover:enabled {
  background-color: rgb(243, 179, 71);
}

button.btn.primary:disabled {
  background-color: rgb(181 157 117);
}

button.secondary {
  color: white;
  background-color: var(--purple);
}

button.btn.secondary:hover:enabled {
  background-color: rgb(105, 69, 108);
}

button.btn.secondary:disabled {
  background-color: rgb(143, 128, 144);
}

select {
  height: 40px;
}

path {
  mix-blend-mode: normal;
}

circle {
  mix-blend-mode: screen;
}

.pathShadow > path {
  filter: drop-shadow(1px -1px 2px #fff);
}

input {
  pointer-events: auto !important;
}

.particle {
  border-radius: 999px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
}